<template>
  <div v-if="!isLoading">
    <b-col
      cols="12"
      class="p-0"
    >
      <PurchaseOrderDetails />
    </b-col>

    <b-col
      cols="12"
      class="mb-2 p-0"
    >
      <b-row>
        <b-col
          cols="12"
          lg="3"
        >
          <MyButton :url="'hypermarket-order'">
            <template #title>
              <feather-icon icon="ArrowLeftIcon" /> {{ $t('go_back_to_hypermarket') }}
            </template>
          </MyButton>
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MyButton from '@/components/MyButton.vue'
import { PurchaseOrderDetails } from '@/components'

export default {
  name: 'ViewPayouts',
  components: {
    MyButton, PurchaseOrderDetails,
  },
  data() {
    return {
      //
    }
  },
  computed: {
    ...mapGetters({
      details: 'orders/orderDetails',
      isLoading: 'orders/checkLoadingState',
    }),
  },
  created() {
    this.getCustomers()
  },
  methods: {
    // this.$route.params.id
    async getCustomers() {
      const orderId = this.$route.params.id
      await this.$store.dispatch('orders/fetchOrder', { id: orderId })
    },
  },
}
</script>
