<template>
  <div>
    <b-button
      :to="{ name: url }"
      variant="primary"
    >
      <slot name="title" />
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'MyButton',
  props: {
    url: {
      required: true,
      type: String,
    },
  },
}
</script>
